import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const ExistingUserRegisterForSMSF = () => {
  return (
    <Layout>
      <Seo
        title="How Can an Existing User Register for SMSF?"
        description={`In SMSF registration, users are given easy instructions on how to achieve verification status after submitting complete details through the Synkli App.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`existing-user-register-for-smsf`} />
    </Layout>
  )
}

export default ExistingUserRegisterForSMSF
